export const shows = [
  { title: "general", price: "$30", description: "Standing tickets" },
  { title: "bronze", price: "$40", description: "3rd and 4th row seating" },
  { title: "silver", price: "$50", description: "2nd row seating" },
  { title: "gold", price: "$60", description: "1st row seating" },
]

export const privates = [
  {
    title: "male strippers",
    price: "$250",
    description: "15 minute routine",
    buttonText: "choose a hunk",
  },
  {
    title: "double trouble",
    price: "$450",
    description: "2 guys for a 30 min show",
    buttonText: "pick your sex",
  },
  {
    title: "topless waiters",
    price: "$100",
    small: true,
    description: "2 hours minimum",
    buttonText: "which abs",
  },
  {
    title: "life drawing",
    price: "$250",
    small: true,
    description: "45 minutes of nude art",
    buttonText: "pick your penis",
  },
]
